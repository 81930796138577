.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

reach-portal > div:nth-of-type(1) {
  z-index: 10000;
}


.dropDownWindow {
  width: 100px;
}

.rc-calendar-column-header .rc-calendar-column-header-inner {
  font-size: 13px;
}

.rc-calendar-today-btn {
  font-size: 13px;
}

*::-webkit-scrollbar {
	width: 4px;
}

*::-webkit-scrollbar-track {
	background: #fff;
}

*::-webkit-scrollbar-thumb {
  background: #f5f5f5;;
}

*:hover::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}

*::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

hr {
  border: 1px solid #f1f1fb;
  margin: 0;
}

.rc-dropdown-arrow {
  display: none;
}

.rc-calendar-input {
  font-size: 13px;
}

.rc-calendar-day-select{
  font-size: 13px;
}

.rc-calendar-range-bottom {
  text-align: left !important;
}

.rc-calendar-range {
  width: 700px !important;
}

.rc-calendar-footer-btn {
  display: flex !important;
  flex-direction: column !important;
  border-right: 1px solid #eeeeee;
  margin-right: 15px;
}

.rc-calendar-panel {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
}

.rc-calendar-date-panel{
  font-size: 13px !important;
}

.rc-calendar-year-select{
  font-size: 13px !important;
}

.rc-calendar-month-select {
  font-size: 13px !important;
}
